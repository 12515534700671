body {
  margin: 0;
  padding-top: 40px;
  white-space: pre-line;
  background-color: black !important;
}

body::-webkit-scrollbar {
  display: none;
}

.content {
  height: 100vh;
}
